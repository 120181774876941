import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PuiAvatarModule } from '@awarenow/profi-ui-core';
import { UiSwitchModule } from 'ngx-ui-switch';

import { SharedModule } from '@app/shared';
import { AuthModule } from '@app/modules/auth/auth.module';

import { UserMenuComponent } from './user-menu.component';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    AuthModule,
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    UiSwitchModule,
    SharedModule,
    PuiAvatarModule,
    TranslocoRootModule
  ],
  declarations: [UserMenuComponent],
  exports: [UserMenuComponent]
})
export class UserMenuModule {}
