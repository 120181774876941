<div class="filter-options-bar">
  <div class="row-m0 d-sm-none">
    <div class="col-md-4 search-block">
      <h3 transloco="findAGuide">Find a coach</h3>
      <div class="search-control">
        <button class="btn search-btn"></button>
        <input
          id="search"
          type="text"
          class="form-control search-input"
          placeholder="Search by name"
          i18n-placeholder="@@searchByName"
          [formControl]="searchControl" />
      </div>
    </div>
  </div>
  <div class="row-m0">
    <div class="col-md-4 guides-and-tags">
      <div class="guides-count-container">
        <span class="label">
          <ng-container i18n="@@searchResult">Search result</ng-container>
          :
        </span>
        <span *transloco="let t" class="guides-count">
          {{ totalCount }}
          <ng-container [ngPlural]="totalCount">
            <ng-template ngPluralCase="=1">{{ t('practitioner') }}</ng-template>
            <ng-template ngPluralCase="other">{{ t('practitioners') }}</ng-template>
          </ng-container>
        </span>
      </div>
    </div>
    <div class="col-md-8 sort">
      <div class="available-now-container">
        <pui-toggle
          [ngModel]="showAvailableNow"
          (ngModelChange)="toggleAvailableNow()"
          i18n="@@availableNow"
          pui-typography="body-s-bold"
          >Available now</pui-toggle
        >
      </div>

      <div class="languages" [formGroup]="languagesForm">
        <app-language-tags
          [items]="languages"
          formControlName="languages"
          [disabled]="config.hideAddLanguages"></app-language-tags>
      </div>

      <div class="focus-area">
        <app-guide-tags [items]="tags$ | async"></app-guide-tags>
      </div>

      <div class="sort-by">
        <span class="label">
          <ng-container i18n="@@sortBy">Sort by</ng-container>
          :
        </span>

        <div class="sort-buttons">
          <button
            type="button"
            class="btn"
            [ngClass]="{
              active: currentSort.sort === sortOptions.NAME,
              order: currentSort.sort === sortOptions.NAME,
              'order-asc': currentSort.sortOrder === 'asc',
              'order-desc': currentSort.sortOrder === 'desc'
            }"
            (click)="updateSort(sortOptions.NAME)">
            <ng-container i18n="@@name">Name</ng-container>
          </button>
          <ng-container *ngIf="!config.hideCoachReviews">
            <button
              type="button"
              class="btn"
              [ngClass]="{
                active: currentSort.sort === sortOptions.RATE,
                order: currentSort.sort === sortOptions.RATE,
                'order-asc': currentSort.sortOrder === 'asc',
                'order-desc': currentSort.sortOrder === 'desc'
              }"
              (click)="updateSort(sortOptions.RATE)"
              i18n="@@ranking">
              Ranking
            </button>
          </ng-container>
          <button
            *ngIf="config.showPriceStartsFrom"
            type="button"
            class="btn"
            [ngClass]="{
              active: currentSort.sort === sortOptions.COST,
              order: currentSort.sort === sortOptions.COST,
              'order-asc': currentSort.sortOrder === 'asc',
              'order-desc': currentSort.sortOrder === 'desc'
            }"
            (click)="updateSort(sortOptions.COST)"
            i18n="@@cost">
            Cost
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-wiki-card class="wiki-section" *ngIf="wiki" [wiki]="wiki"></app-wiki-card>

<div class="guide-cards-container">
  <div class="row-m0" style="align-items: stretch">
    <!-- eslint-disable -->

    <ng-template ngFor let-guide [ngForOf]="guides" let-i="index">
      <app-guide-card class="col-hg-4 col-lg-6" [guide]="guide"></app-guide-card>
      <app-gift-certificate
        *ngIf="!config.hideGiftCertificate && i == 1"
        [ngClass]="{ 'd-hg-none': guides.length > 2 }"></app-gift-certificate>
      <app-gift-certificate
        *ngIf="!config.hideGiftCertificate && i == 2"
        class="d-none d-hg-block"></app-gift-certificate>
    </ng-template>

    <!-- eslint-enable -->
  </div>
</div>
